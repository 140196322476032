export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/blog": [~4],
		"/blog/[...slug]": [~5],
		"/contribute/step-1": [~6],
		"/contribute/step-2": [~7],
		"/contribute/step-3": [~8],
		"/contribute/step-4": [~9],
		"/contributions": [~10],
		"/contributions/map": [~12],
		"/contributions/[contributionId]": [~11],
		"/login": [~13],
		"/logout": [14]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';